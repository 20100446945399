export { default as pageLinks } from "./pagelinks";
export { default as endpoints } from "./endpoints";
export { default as queryKeys } from "./querykeys";
export { default as mantineTheme } from "./mantineTheme";

export const orderStatus = {
  orderCreated: "order-created",
  receivedOrder: "received-order",
  pendingOrder: "pending-order",
  prelimResult: "prelim-result",
  finalizedResult: "finalized-result",
  released: "released",
};
export const USAStates = [
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
  { value: "District of Columbia", label: "District of Columbia" },
];

export const Countries = [
  { value: "United States", label: "United States" },
  { value: "Canada", label: "Canada" },
  { value: "Mexico", label: "Mexico" },
  { value: "Brazil", label: "Brazil" },
  { value: "Argentina", label: "Argentina" },
  { value: "Colombia", label: "Colombia" },
  { value: "Chile", label: "Chile" },
  { value: "Peru", label: "Peru" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Cuba", label: "Cuba" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Panama", label: "Panama" },
  { value: "Honduras", label: "Honduras" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Paraguay", label: "Paraguay" },
];

export const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  USER: "USER",
  PROVIDER: "PROVIDER",
  PATIENT: "PATIENT",
  CLINICIAN: "CLINICIAN",
  ACCESSIONER: "ACCESSIONER",
  MASTER_USER: "MASTER_USER",
};

export const customerType = {
  CLINICAL: "clinical",
  ANATOMICAL: "anatomical",
};

const roleSet = new Set(Object.values(ROLES));

const showRoles = {
  SUPER_ADMIN: "Admin",
  ADMIN: "Master User",
  USER: "Accessioner",
  PROVIDER: "Provider",
  PATIENT: "Patient",
  CLINICIAN: "Clinician",
  ACCESSIONER: "Accessioner",
  MASTER_USER: "Master User",
};

export const resultState = {
  positive: "positive",
  negative: "negative",
  none: "none",
};

export const OrgTypes = [
  {
    value: "hospital",
    label: "Hospital",
  },
  {
    value: "laboratory",
    label: "Laboratory",
  },
  {
    value: "medical-center",
    label: "Medical Center",
  },
  {
    value: "multi-clinic",
    label: "Multi Clinic",
  },
  {
    value: "clinic",
    label: "Clinic",
  },
];

export const Titles = [
  { value: "Mrs", label: "Mrs" },
  { value: "Miss", label: "Miss" },
  { value: "Mr", label: "Mr" },
  { value: "Dr", label: "Dr" },
];

export const role = (role: keyof typeof showRoles) => {
  if (roleSet.has(role)) {
    return showRoles[role as keyof typeof showRoles];
  }
  return "";
};

export const genders = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "unknown", label: "Unknown" },
];

export const maritalStatus = [
  { value: "single", label: "Single" },
  { value: "married", label: "Married" },
  { value: "divorced", label: "Divorced" },
  { value: "widow", label: "Widow" },
];

export const ethnicity = [
  { value: "hispanic", label: "Hispanic" },
  { value: "not-hispanic", label: "Not-Hispanic" },
  { value: "unknown", label: "Unknown" },
];

export const race = [
  { value: "white", label: "White" },
  {
    value: "black-or-african-american",
    label: "Black or African American",
  },
  {
    value: "american-indian-and-alaska-native",
    label: "American Indian and Alaska Native",
  },
  { value: "asian", label: "Asian" },
  {
    value: "native-hawaiian-and-other-pacific-islander",
    label: "Native Hawaiian and Other Pacific Islander",
  },
  {
    value: "hispanic-or-latino",
    label: "Hispanic or Latino",
  },
  { value: "other", label: "Other" },
];

export const VENDOR_CONNECTION_INTEGRATION_METHODS = [
  { value: "FTP", label: "FTP" },
  { value: "FHIR", label: "FHIR" },
  { value: "HL7", label: "HL7" },
  { value: "CUSTOM_API", label: "Custom Api" },
];

export const BULK_REPORT_TYPES = [
  {
    label: "Accounting Report",
    value: "accounting-report",
  },
  // {
  //   label: "Amended Result Report",
  //   value: "amended-result-report",
  // },
  // {
  //   label: "Batch Label",
  //   value: "batch-label",
  // },
  // {
  //   label: "Batch Label by Panels",
  //   value: "batch-label-panels",
  // },
  {
    label: "Batch Order Audit",
    value: "batch-order-audit",
  },
  {
    label: "Batch Requisition",
    value: "batch-requisition",
  },
  {
    label: "Batch Result",
    value: "batch-result",
  },
  // {
  //   label: "Batch Result Panel Filter",
  //   value: "batch-result-panel",
  // },
  {
    label: "Billing",
    value: "billing",
  },
  {
    label: "Detected Summary Report",
    value: "detected-summary-report",
  },
  {
    label: "Flag Result Report",
    value: "flag-result-report",
  },
  {
    label: "Inconsistent Summary",
    value: "inconsistent-summary",
  },
  {
    label: "Instrument Report",
    value: "instument-report",
  },
  {
    label: "Method Result",
    value: "method-result",
  },
  {
    label: "Patient Listing Report",
    value: "patient-listing-report",
  },
  {
    label: "Received Samples Report",
    value: "received-samples-report",
  },
  {
    label: "Requisition Listing",
    value: "requisition-listing",
  },
  {
    label: "Test Cancellation Report",
    value: "test-cancellation-report",
  },
];