const pageLinks = {
  login: "/login",
  dashboard: "/dashboard",
  patients: "/patients",
  newPatient: "/patients/new",
  insurance: "/insurance",
  orders: "/orders",
  frequentTasks: "/frequent-tasks",
  insuranceCompanies: "/insurance-companies",
  faxReport: "/fax-report",
  sendAndReceiveApiOrdersOrResults: "/send-and-receive-api-orders-or-results",
  integrationPartnerSetup: "/integration-partner-setup",
  dataSetup: {
    dataSetupLists: "/data-setup",
    insurancePlan: "/data-setup/insurance-plan",
    insuranceType: "/data-setup/insurance-type",
    sampleType: "/data-setup/sample-type",
    insuranceGroup: "/data-setup/insurance-group",
    insuranceRuleType: "/data-setup/insurance-rule-type",
    testMethodType: "/data-setup/test-method-type",
  },
  labSetup: {
    organization: "/lab-setup/organization",
    newOrganization: "/lab-setup/organization/new",
    location: "/lab-setup/location",
    newLocation: "/lab-setup/location/new",
    provider: "/lab-setup/provider",
    clinician: "/lab-setup/clinician",
    newClinician: "/lab-setup/clinician/new",
    newProvider: "/lab-setup/provider/new",
    users: "/lab-setup/users",
    usersAndProviders: "/lab-setup/users-and-providers",
    newUser: "/lab-setup/users/new",
  },
  testSetup: {
    testMethods: "/test-setup/test-methods",
    newTestMethod: "/test-setup/test-methods/new",
    tests: "/test-setup/tests",
    newTest: "/test-setup/tests/new",
    container: "/test-setup/container",
    orderSets: "/test-setup/order-sets",
    newOrderSet: "/test-setup/order-sets/new",
    panels: "/test-setup/panels",
    newPanel: "/test-setup/panels/new",
    masterPanel: "/test-setup/master-panels",
    linkMasterPanelWithMasterTests:
      "/test-setup/master-panels/link-master-tests",
    newMasterPanel: "/test-setup/master-panels/new",
    masterTestAndPanel: "/test-setup/master-test-and-panel",
    comboPanel: "/test-setup/combo-panels",
  },
  librarySetup: {
    cptAndIcd10Codes: "/library-setup/cpt-and-icd10-codes",
    diagnosisCodes: "/library-setup/diagnosis-codes",
    newDiagnosisCode: "/library-setup/diagnosis-codes/new",
    medications: "/library-setup/medications",
    newMedication: "/library-setup/medications/new",
    cptCodes: "/library-setup/cpt-codes",
    newCptCode: "/library-setup/cpt-codes/new",
    icd10Codes: "/library-setup/icd10-codes",
    newIcd10Code: "/library-setup/icd10-codes/new",
  },
  commentLibrary: "/comments-library-setup",
  diagnosisLibrary: "/diagnosis-library-setup",
  inbox: "/inbox",
  createOrder: "/create-order",
  reports: "/reports",
  bulkReports: "/bulk-reports",
  ai: {
    ai: "/ai"
  }
};

export default pageLinks;
