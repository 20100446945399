import "../styles/global.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "../styles/app.css";
import { AppProps } from "next/app";
import { useState, useEffect } from "react";
import { ThemeProvider } from "next-themes";
import { Inter } from "next/font/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "@/components/ui/toaster";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { mantineTheme } from "@/constants/";
import "@mantine/spotlight/styles.css";

const inter = Inter({ subsets: ["latin"] });

function App({ Component, pageProps }: AppProps) {
  const [isClient, setIsClient] = useState(false);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
      },
    },
  });

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return <></>;
  }
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider attribute="class">
        <MantineProvider theme={mantineTheme} defaultColorScheme="dark">
          <Notifications />
          <main className={inter.className}>
            <Component {...pageProps} />
          </main>
          <Toaster />
        </MantineProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
